import React, { useState } from "react"
import { navigate } from "gatsby"
import { useAmIAWorker } from "../../contexts/AmIAWorkerContext"
import { useSpring, animated, config } from "react-spring"
import forEach from "lodash/forEach"
import MailchimpSubscribe from "react-mailchimp-subscribe"

import { questionList, Radio } from "../../components/amiaworker/questions"
import map from "lodash/map"
import AmIAWorkerLayout from "../../components/amiaworker/layout"

const CalculateResult = () => {
  const url =
    "https://thelitigationfriend.us1.list-manage.com/subscribe/post?u=5aca476e4568ad5664719b647&id=8df2c567fc"
  const { setEmailSubbed, emailSubbed } = useAmIAWorker()

  const SimpleForm = ({ status, message, className, style, onSubmitted }) => {
    const [tipsBox, setTipsBox] = useState(true)
    const { amIAWorker } = useAmIAWorker()
    const {
      percentage,
      employeeIndicatorsScore,
      totalScore,
      maxScore,
      listOfAnswers,
    } = calculatePercentage(amIAWorker)

    let input
    let firstName
    let lastName
    let companyName
    let workerType = ""
    let workerDetails = ""
    let roundedPercentage = percentage.toFixed(2)

    if (percentage >= 40 && employeeIndicatorsScore > 0) {
      workerDetails =
        roundedPercentage +
        "% | eis: " +
        employeeIndicatorsScore +
        " | " +
        totalScore +
        "/" +
        maxScore
      workerType = "Employee"
    } else if (percentage >= 60) {
      workerDetails =
        roundedPercentage +
        "% |  eis: " +
        employeeIndicatorsScore +
        " | " +
        totalScore +
        "/" +
        maxScore
      workerType = "Very Likely Worker"
    } else if (percentage >= 40) {
      workerDetails =
        roundedPercentage +
        "% |  eis: " +
        employeeIndicatorsScore +
        " | " +
        totalScore +
        "/" +
        maxScore
      workerType = "Likely Worker"
    } else {
      workerDetails =
        roundedPercentage +
        "% |  eis: " +
        employeeIndicatorsScore +
        " | " +
        totalScore +
        "/" +
        maxScore
      workerType = "Self Employed"
    }

    const submit = () => {
      if (input && firstName && lastName && input.value.indexOf("@") > -1) {
        onSubmitted({
          EMAIL: input.value,
          FNAME: firstName.value,
          LNAME: lastName.value,
          CONAME: companyName.value,
          AMIAWORKER: workerType,
          AMIAWD: workerDetails,
          AMIAWANW: listOfAnswers,
          "gdpr[42870]": tipsBox ? "Y" : "N",
        })
        setEmailSubbed(true)
      }
    }

    return (
      <div className={className} style={style}>
        <label className="text-green-500 font-bold" htmlFor="mchimpEmail">
          Email*
        </label>
        <input
          ref={node => (input = node)}
          type="email"
          className="form-input mt-1 mb-4 block rounded-md border-4 border-black bg-white	w-full md:w-3/6"
          placeholder="Your Email"
          required
        />
        <label className="text-green-500 font-bold" htmlFor="mce-FNAME">
          First Name*
        </label>
        <input
          ref={node => (firstName = node)}
          type="text"
          className="form-input mt-1 mb-4 block rounded-md border-4 border-black bg-white	w-full md:w-3/6"
          placeholder="First Name"
          name="FNAME"
          id="mce-FNAME"
          required
        />
        <label className="text-green-500 font-bold" htmlFor="mce-LNAME">
          Last Name*
        </label>
        <input
          ref={node => (lastName = node)}
          type="text"
          className="form-input mt-1 mb-4 block rounded-md border-4 border-black bg-white	w-full md:w-3/6"
          placeholder="Last Name"
          name="LNAME"
          id="mce-LNAME"
          required
        />
        <label className="text-green-500 font-bold" htmlFor="mce-CONAME">
          Company Name
        </label>
        <input
          ref={node => (companyName = node)}
          type="text"
          className="form-input mt-1 mb-4 block rounded-md border-4 border-black bg-white	w-full md:w-3/6"
          placeholder="Company Name"
          name="CONAME"
          id="mce-CONAME"
        />
        <label className="b-contain md:w-6/12">
          <span className="ml-4">
            I would like to receive useful tips and guidance from Litigation
            Friend about my employment rights.
          </span>

          <input
            name="confirmed"
            type="checkbox"
            onChange={e => setTipsBox(e.target.checked)}
            defaultChecked={tipsBox}
          />
          <div class="b-input"></div>
        </label>

        <p className="text-gray-700 mb-4 text-sm md:w-6/12	">
          We promise not to bombard you and you can unsubscribe at any time!
        </p>

        <p className="text-gray-700 mb-4 text-sm md:w-6/12	">
          Please see Litigation Friend’s{" "}
          <a className="text-green-500" target="_blank" href="/privacy">
            privacy policy
          </a>{" "}
          for details of how we handle your personal data.
        </p>
        <button
          onClick={submit}
          className="bg-green-500 text-white px-8 py-2 rounded-md font-bold border-4 border-green-500"
        >
          Submit
        </button>
      </div>
    )
  }

  return (
    <>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => {
          return (
            <div>
              {status !== "error" && status !== "success" && !emailSubbed && (
                <>
                  <h3 className="mb-4 font-bold md:text-2xl	">
                    Subscribe to our newsletter to reveal your results!
                  </h3>
                  <SimpleForm
                    onSubmitted={formData => {
                      subscribe(formData)
                    }}
                  />
                </>
              )}

              {(status === "error" || status === "success" || emailSubbed) && (
                <>
                  <ShowResult />
                  <hr className="my-4" />
                  <p>
                    The above is a guide only and does not constitute legal
                    advice. Please see our{" "}
                    <a className="text-green-500" target="_blank" href="/terms">
                      Terms and Conditions
                    </a>{" "}
                    for more information
                  </p>
                </>
              )}
            </div>
          )
        }}
      />
    </>
  )
}

const calculatePercentage = amIAWorker => {
  let maxScore = 104
  let listOfAnswers = ""

  if (amIAWorker[questionList[8].name] === "c") {
    maxScore -= 5
  }

  if (amIAWorker[questionList[9].name] === "c") {
    maxScore -= 5
  }

  if (amIAWorker[questionList[4].name] === "a") {
    maxScore -= 5
  }

  // constMaxScore = 104 - questionList[10].answers[amIAWorker?.[questionList[10]['c']]].score
  let totalScore = 0
  forEach(questionList, function (value, key) {
    listOfAnswers += amIAWorker?.[questionList[key].name] + " | "
    if (questionList[key].answers[amIAWorker?.[questionList[key].name]]) {
      totalScore +=
        questionList[key].answers[amIAWorker?.[questionList[key].name]].score
    }
  })

  const percentage = (totalScore / maxScore) * 100

  const employeeIndicatorsScore =
    (amIAWorker[questionList[3].name] === "a"
      ? questionList[3].answers[amIAWorker?.[questionList[3].name]].score
      : 0) +
    (amIAWorker[questionList[10].name] === "e"
      ? questionList[10].answers[amIAWorker?.[questionList[10].name]].score
      : 0) +
    (amIAWorker[questionList[11].name] === "c"
      ? questionList[11].answers[amIAWorker?.[questionList[11].name]].score
      : 0) +
    +(amIAWorker[questionList[13].name] === "a"
      ? questionList[13].answers[amIAWorker?.[questionList[13].name]].score
      : 0)

  // console.log("maxScore", maxScore)
  // console.log("totalScore", totalScore)
  // console.log("percentage", percentage)
  // console.log("employee Indicator Score", employeeIndicatorsScore)
  return {
    percentage,
    employeeIndicatorsScore,
    totalScore,
    maxScore,
    listOfAnswers,
  }
}
const ShowResult = () => {
  const { amIAWorker } = useAmIAWorker()

  const { percentage, employeeIndicatorsScore } = calculatePercentage(
    amIAWorker
  )

  if (percentage >= 40 && employeeIndicatorsScore > 0) {
    return <Employee />
  }

  if (percentage >= 60) {
    return <VeryLikelyWorker />
  }

  if (percentage >= 40) {
    return <LikelyWorker />
  }

  return <SelfEmployed />
}

const VeryLikelyWorker = () => {
  return (
    <div>
      <h3 className="text-pink-800 font-bold text-5xl mb-8 md:text-7xl">
        Worker
      </h3>

      <hr className="mb-4" />
      <p className="mb-4">
        It sounds very likely that you could qualify as a{" "}
        <span className="text-green-500 font-bold">"worker"</span>. If you are a{" "}
        <span className="text-green-500 font-bold">"worker"</span> you could be
        entitled to the following:
      </p>
      <ul className="mb-4">
        <li>National minimum wage</li>
        <li>Statutory sick pay</li>
        <li>Statutory holiday pay </li>
        <li>Discrimination and Whistleblowing rights</li>
        <li>Pension auto-enrolment</li>
        <li>Maternity/Paternity pay</li>
      </ul>
      <p>
        Maternity/Paternity pay If you would like to discuss your employment
        rights further, please contact us at{" "}
        <a
          className="text-green-500 font-bold"
          href="mailto:hello@thelitigationfriend.com"
        >
          hello@thelitigationfriend.com
        </a>
      </p>
    </div>
  )
}

const LikelyWorker = () => {
  return (
    <div>
      <h3 className="text-pink-800 font-bold text-5xl mb-8 md:text-7xl">
        Worker
      </h3>

      <hr className="mb-4" />
      <p className="mb-4">
        It sounds possible that you could qualify as a{" "}
        <span className="text-green-500 font-bold">"worker"</span>. If you are a{" "}
        <span className="text-green-500 font-bold">"worker"</span> you could be
        entitled to the following:
      </p>
      <ul className="mb-4">
        <li>National minimum wage</li>
        <li>Statutory sick pay</li>
        <li>Statutory holiday pay </li>
        <li>Discrimination and Whistleblowing rights</li>
        <li>Pension auto-enrolment</li>
        <li>Maternity/Paternity pay</li>
      </ul>
      <p>
        pay If you would like to discuss your employment rights further, please
        contact us at{" "}
        <a
          className="text-green-500 font-bold"
          href="mailto:hello@thelitigationfriend.com"
        >
          hello@thelitigationfriend.com
        </a>
      </p>
    </div>
  )
}

const SelfEmployed = () => {
  return (
    <div>
      <h3 className="text-pink-800 font-bold text-5xl mb-8 md:text-7xl">
        Self-employed
      </h3>

      <hr className="mb-4" />
      <p className="mb-4">
        It sounds like you are genuinely{" "}
        <span className="text-green-500 font-bold">"self-employed"</span> and so
        are not a <span className="text-green-500 font-bold">"worker"</span>. If
        you would like to discuss your employment rights further, please contact
        us at{" "}
        <a
          className="text-green-500 font-bold"
          href="mailto:hello@thelitigationfriend.com"
        >
          hello@thelitigationfriend.com
        </a>
      </p>
    </div>
  )
}

const Employee = () => {
  return (
    <div>
      <h3 className="text-pink-800 font-bold text-5xl mb-8 md:text-7xl">
        Worker / Employee
      </h3>
      <hr className="mb-4" />
      <p className="mb-4">
        It sounds likely that you could qualify as a{" "}
        <span className="text-green-500 font-bold">"worker"</span> and you might
        even qualify as an{" "}
        <span className="text-green-500 font-bold">"employee"</span> you could
        be entitled to the following:
      </p>
      <ul className="mb-4">
        <li>National minimum wage</li>
        <li>Statutory sick pay</li>
        <li>Statutory holiday pay </li>
        <li>Discrimination and Whistleblowing rights</li>
        <li>Pension auto-enrolment</li>
        <li>Maternity/Paternity pay</li>
      </ul>
      <p className="mb-4">
        If you also qualify as an{" "}
        <span className="text-green-500 font-bold">“employee”</span> you would
        be entitled to all of the above plus the following:
      </p>
      <ul className="mb-4">
        <li>Unfair dismissal rights</li>
        <li>Rights to a redundancy payment</li>
        <li>Maternity/Paternity leave</li>
      </ul>
      <p>
        If you would like to discuss your employment rights further, please
        contact us at{" "}
        <a
          className="text-green-500 font-bold"
          href="mailto:hello@thelitigationfriend.com"
        >
          hello@thelitigationfriend.com
        </a>
      </p>
    </div>
  )
}

export default function AmIAWorkerResults({ data, location }) {
  const seo = {
    metaTitle: "Am I a worker",
    metaDescription:
      "Find out if you are a worker, self-employed or an employee",
    url: location.pathname,
  }
  const { setValues, amIAWorker } = useAmIAWorker()
  const currentPage = 14
  const nextPage = currentPage + 1
  const previousPage = currentPage > 1 ? currentPage - 1 : 1

  const goBack = () => {
    navigate(`/amiaworker/question${previousPage}/`)
  }

  const cfg = {
    config: config.gentle,
    from: {
      transform: "perspective(700px) translate3d(0, -15px, -15px)",
      opacity: 0.7,
    },
    to: { transform: "perspective(700px) translate3d(0, 0, 0)", opacity: 1.0 },
  }

  const springAnimation = useSpring(cfg)

  return (
    <AmIAWorkerLayout seo={seo} currentPage={14}>
      <animated.div style={springAnimation}>
        <div className="rounded-md p-8 shadow-xl h-full bg-white overflow-hidden justify-between border-gray-50 border md:max-w-screen-lg">
          <div className="block text-md font-bold mb-8 text-white bg-green-500 p-4 -m-8 md:text-3xl">
            Result
          </div>

          <CalculateResult amIAWorker={amIAWorker} />
          <hr className="my-6" />
          <button
            onClick={goBack}
            className=" border-black font-bold text-md text-center border-4  rounded-md py-2 px-8 transition duration-500 ease select-none hover:text-white hover:bg-pink-800 focus:outline-none hover:border-pink-800 focus:shadow-outline self-start"
          >
            Back
          </button>
        </div>
      </animated.div>
    </AmIAWorkerLayout>
  )
}
